import { render } from "./DashboardSpaceDetails.vue?vue&type=template&id=5a40a556"
import script from "./DashboardSpaceDetails.vue?vue&type=script&lang=js"
export * from "./DashboardSpaceDetails.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5a40a556"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5a40a556', script)) {
    api.reload('5a40a556', script)
  }
  
  module.hot.accept("./DashboardSpaceDetails.vue?vue&type=template&id=5a40a556", () => {
    api.rerender('5a40a556', render)
  })

}

script.__file = "src/components/dashboard/DashboardSpaceDetails.vue"

export default script